import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import('../views/CursosView.vue')
  },

  {
    path: '/galeria',
    name: 'galeria',
    component: () => import('../views/GaleriaView.vue')
  },

  {
    path: '/sobre',
    name: 'sobre',
    component: () => import('../views/SobreView.vue')
  },

  {
    path: '/videos',
    name: 'videos',
    component: () => import('../views/VideosView.vue')
  },

  {
    path: '/livros',
    name: 'livros',
    component: () => import('../views/LivrosView.vue')
  },

  {
    path: '/contato',
    name: 'contato',
    component: () => import('../views/ContatoView.vue')
  },

  {
    path: '/politica-de-privacidade',
    name: 'politica-de-privacidade',
    component: () => import('../views/PoliticaDePrivacidade.vue')
  },

  {
    path: '/termos-condicoes',
    name: 'termos-condicoes',
    component: () => import('../views/TermosCondicoes.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
