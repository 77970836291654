<template>


  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/cursos">Cursos</router-link>
    
  </nav> -->
  <RouterView />
  
  

</template>




