

const vemProTime = () => {
    console.log(
        "\n" +
        "   _   _ _                       _\n" +
        " / _ \\/ / | |__  _ __ __ _ ___(_) |\n" +
        "| | | | | | '_ \\| '__/ _` / __| | |\n" +
        "| |_| | | | |_) | | | (_| \\__ \\ | |\n" +
        " \\___/|_|_|_.__/|_|  \\__,_|___/_|_|" +
        "\n" +
        "\n" +
        "Vem pro time!" +
        "\n" +
        "https://011brasil.com.br - time@011brasil.com.br" +
        "\n" +
        " "
    );
}

const disableConsoleDebug = (debug = false) => {

    // let debug = false;
    // console.log('debug', debug)
    
    if (!debug) {
        if (!window.console) window.console = {};

        let methods = ["log", "debug", "warn", "info", "error"];

        for (let i = 0; i < methods.length; i++) {
            console[methods[i]] = function () { };
        }
    }

}

vemProTime();
disableConsoleDebug(true);