// alert('FOI')

window.onload = () => {

  const aquarelaLink = document.querySelector("#aquarela-link");
  const lapisCorLink = document.querySelector("#lapis-cor-link");

  const galeriaImagensAquarela = document.querySelector(
    "#galeria-imagens-aquarela"
  );
  const carroselAquarela = document.querySelector("#carrosel-aquarela");
  const galeriaCarouselInner = document.querySelector("#galeria-carousel-inner");
  const aquarelaUm = document.querySelector("#aquarela-um");
  const aquarelaDois = document.querySelector("#aquarela-dois");
  const aquarelaTres = document.querySelector("#aquarela-tres");

  const galeriaImagensLapis = document.querySelector("#galeria-imagens-lapis");
  // const carroselLapis = document.querySelector("#carrosel-lapis");
  // const galeriaCarouselLapis = document.querySelector("#galeria-carousel-lapis");
  const lapisUm = document.querySelector("#lapis-um");
  const lapisDois = document.querySelector("#lapis-dois");
  const lapisTres = document.querySelector("#lapis-tres");
  const lapisQuatro = document.querySelector("#lapis-quatro");

  if (window.location.href.includes("galeria=aquarela")) {
    if (aquarelaLink) {
      aquarelaLink.style.color = "#8338EC";
      aquarelaLink.style.borderBottom = "3.5px solid #8338EC";
    }
    document.querySelector("#galeria-imagens-lapis").style.display = "none";
    document.querySelector("#galeria-imagens-aquarela").style.display = "grid";

  } else if (window.location.href.includes("galeria=lapis")) {
    if (lapisCorLink) {
      lapisCorLink.style.color = "#8338EC";
      lapisCorLink.style.borderBottom = "3.5px solid #8338EC";
    }
    document.querySelector("#galeria-imagens-aquarela").style.display = "none";
    document.querySelector("#galeria-imagens-lapis").style.display = "grid";
  }

  if (aquarelaLink && lapisCorLink) {
    console.log("tem os dois", aquarelaLink, lapisCorLink);
    aquarelaLink.addEventListener("click", function () {
      aquarelaLink.style.color = "#8338EC";
      aquarelaLink.style.borderBottom = "3.5px solid #8338EC";
      lapisCorLink.style.color = "#fff";
      lapisCorLink.style.borderBottom = "none";
      document.querySelector("#galeria-imagens-lapis").style.display = "none";
      document.querySelector("#carrosel-aquarela").style.display = "none";
      document.querySelector("#galeria-imagens-aquarela").style.display = "grid";

      while (galeriaCarouselInner.firstChild) {
        galeriaCarouselInner.removeChild(galeriaCarouselInner.firstChild);
      }
    });

    lapisCorLink.addEventListener("click", function () {
      aquarelaLink.style.color = "#fff";
      aquarelaLink.style.borderBottom = "none";
      lapisCorLink.style.color = "#8338EC";
      lapisCorLink.style.borderBottom = "3.5px solid #8338EC";
      document.querySelector("#galeria-imagens-aquarela").style.display = "none";
      document.querySelector("#carrosel-aquarela").style.display = "none";
      document.querySelector("#galeria-imagens-lapis").style.display = "grid";

      while (galeriaCarouselInner.firstChild) {
        galeriaCarouselInner.removeChild(galeriaCarouselInner.firstChild);
      }
    });
  }

  // CRIA AS GALERIAS
  if (galeriaImagensAquarela && galeriaImagensAquarela.children.length <= 3) {
    for (let i = 4; i < 42; i++) {
      const galeryItem = document.createElement("div");
      galeryItem.classList.add("div-branca");
      galeryItem.setAttribute("id", `galery-item-${i}`);
      galeryItem.style.background = `url("imgs/galeria/aquarela/${i}.jpg")`;
      galeryItem.addEventListener("click", function () {
        createCarousel(i);
      });

      galeriaImagensAquarela.appendChild(galeryItem);
    }
  }

  if (galeriaImagensLapis && galeriaImagensLapis.children.length <= 4) {
    for (let i = 5; i < 41; i++) {
      const galeryItem = document.createElement("div");
      galeryItem.classList.add("div-branca");
      galeryItem.setAttribute("id", `lapis-item-${i}`);
      galeryItem.style.background = `url("imgs/galeria/lapis/${i}.jpg")`;
      galeryItem.addEventListener("click", function () {
        createLapisCarousel(i);
      });

      galeriaImagensLapis.appendChild(galeryItem);
    }
  }

  // CARROSSEL GALERIAS
  if (aquarelaUm) {
    aquarelaUm.addEventListener("click", function () {
      createCarousel(1);
    });
  }
  if (aquarelaDois) {
    aquarelaDois.addEventListener("click", function () {
      createCarousel(2);
    });
  }
  if (aquarelaTres) {
    aquarelaTres.addEventListener("click", function () {
      createCarousel(3);
    });
  }

  if (lapisUm) {
    lapisUm.addEventListener("click", function () {
      createLapisCarousel(1);
    });
  }
  if (lapisDois) {
    lapisDois.addEventListener("click", function () {
      createLapisCarousel(2);
    });
  }
  if (lapisTres) {
    lapisTres.addEventListener("click", function () {
      createLapisCarousel(3);
    });
  }
  if (lapisQuatro) {
    lapisQuatro.addEventListener("click", function () {
      createLapisCarousel(4);
    });
  }


  function createCarousel(activeImage) {
    try {
      let galeImgAquerela = document.querySelector("#galeria-imagens-aquarela");
      if (galeImgAquerela) {
        galeImgAquerela.style.display = "none";
      }

      for (let i = 1; i < 42; i++) {
        let imageCarousel = document.createElement("img");
        imageCarousel.classList.add("d-block", "w-100");
        imageCarousel.src = `imgs/galeria/aquarela/${i}.jpg`;

        let carouselItem = document.createElement("div");
        carouselItem.setAttribute("id", `carousel-item-${i}`);
        if (i === activeImage) {
          carouselItem.classList.add("carousel-item", "active");
        } else {
          carouselItem.classList.add("carousel-item");
        }
        carouselItem.appendChild(imageCarousel);

        galeriaCarouselInner.appendChild(carouselItem);
      }

      carroselAquarela.style.display = "block";
    } catch (error) {
      console.error("falha ao rodar createCarousel ", error);
    }
  }

  function createLapisCarousel(activeImage) {
    document.querySelector("#galeria-imagens-lapis").style.display = "none";

    for (let i = 1; i < 41; i++) {
      let imageCarousel = document.createElement("img");
      imageCarousel.classList.add("d-block", "w-100");
      imageCarousel.src = `imgs/galeria/lapis/${i}.jpg`;

      let carouselItem = document.createElement("div");
      carouselItem.setAttribute("id", `carousel-lapis-item-${i}`);
      if (i === activeImage) {
        carouselItem.classList.add("carousel-item", "active");
      } else {
        carouselItem.classList.add("carousel-item");
      }
      carouselItem.appendChild(imageCarousel);

      galeriaCarouselInner.appendChild(carouselItem);
    }

    carroselAquarela.style.display = "block";
  }

  // let images = ['../assets/imgs/galeria-home/home-1.webp', '../assets/imgs/galeria-home/home-2.webp', '../assets/imgs/galeria-home/home-3.webp']
  // let i = 0
  // function slideShow() {}

  // if (i < images.length - 1) {
  //   i++
  // } else {
  //   i = 0
  // }

  // console.log('Hello')

}